import { i18n } from "~/plugins/i18n";
import { useEventStore } from '~/stores/event'
import { useAppStateStore } from '~/stores/appState'
import { useNotificationStore } from '~/stores/notification'
import { useAuthStore } from '~/stores/auth'

import Promptpay_info from '~/pages/v2/promptpay/main.vue'
import Promptpay_share from '~/pages/v2/promptpay/qrcodeAndLink.vue'
import Promptpay_howtouse from '~/pages/v2/promptpay/howToUse.vue'
import Promptpay_faqs from '~/pages/v2/promptpay/faqs.vue'

import Swal from "sweetalert2";
import download from '~/services/api/download'


export const useContentPromptpay = (page: string) => {

  const appStateStore = useAppStateStore();
  const eventStore = useEventStore();
  const notificationStore = useNotificationStore();
  const authStore = useAuthStore();

  const lang = computed(() => i18n.global.locale.value)
  const isThaiLang = computed(() => lang.value === 'th')

  // ------------- Promptpay ------------------

  const contentPromptpayHome = computed(() => {
    return {
      title: isThaiLang.value ? "พร้อมเพย์และธนาคาร" : "Promptpay and Bank",
      menus: [
        {
          // name: isThaiLang.value ? "ข้อมูลพร้อมเพย์และธนาคาร" : "Information",
          name: isThaiLang.value ? "ข้อมูลพร้อมเพย์" : "Information",
          icon: "fa-solid fa-calendar-lines-pen",
          id: 'PromptpayInfo',
          callback: () => {
            appStateStore.setMenuSelected("PromptpayInfo");
          },
        },
        {
          name: isThaiLang.value ? "Qr Code / แชร์" : "Qr Code / Share",
          icon: " fa-regular fa-qrcode",
          id: 'PromptpayQrcodeAndLink',
          callback: () => {
            appStateStore.setMenuSelected("PromptpayQrcodeAndLink");
          },
        },
        // {
        //   name: isThaiLang.value ? "ขั้นตอนการใช้งาน" : "How to Use",
        //   icon: "fa-duotone fa-solid fa-book",
        //   id: 'PromptpayHowToUse',
        //   callback: () => {
        //     appStateStore.setMenuSelected("PromptpayHowToUse");
        //   },
        // },
        // {
        //   name: isThaiLang.value ? "คำถามที่พบบ่อย" : "FAQs",
        //   icon: "fa-solid fa-message-question",
        //   id: 'PromptpayFaqs',
        //   callback: () => {
        //     appStateStore.setMenuSelected("PromptpayFaqs");
        //   },
        // }, 
      ],
      components: {
        'PromptpayInfo': Promptpay_info,
        'PromptpayQrcodeAndLink': Promptpay_share,
        'PromptpayHowToUse': Promptpay_howtouse,
        'PromptpayFaqs': Promptpay_faqs,
      }
    }
  })

  const contentPromptpayInfo = computed(() => {
    return {
      // title: isThaiLang.value ? "พร้อมเพย์ หรือ ธนาคาร" : "Promptpay or Bank Account",
      title: isThaiLang.value ? "พร้อมเพย์ " : "Promptpay Account",
      active: isThaiLang.value ? "เปิดใช้ระบบ" : "Active",
      promptpayTab: isThaiLang.value ? "พร้อมเพย์ (แนะนำ)" : "Promptpay (Recommend)",
      bankAccountTab: isThaiLang.value ? "ธนาคาร" : "Bank Account",
      fullName: isThaiLang.value ? "ชื่อ - นามสกุล" : "Full Name",
      fullNameEdit: isThaiLang.value ? "ชื่อ - นามสกุล (ไม่ต้องระบุคำนำหน้า)" : "Full Name (no prefix required)",
      promptpayAccount: isThaiLang.value ? "พร้อมเพย์" : "Promptpay Account",
      promptpayAccountEdit: isThaiLang.value ? "พร้อมเพย์ (เบอร์โทรศัพท์ หรือ เลขประจำตัวประชาชนที่ผูกกับพร้อมเพย์)" : "Promptpay Account (Phone number or National ID number linked to Propmtpay)",
      selectBank: isThaiLang.value ? "เลือกธนาคาร" : "Select Bank",
      selectBankPlaceholder: isThaiLang.value ? "กรุณาเลือกธนาคาร" : "Please select a bank",
      accountName: isThaiLang.value ? "ชื่อบัญชี" : "Account Name",
      example: isThaiLang.value ? "ตัวอย่างรูป QRCode รับเงิน" : "Example QRCode image",
      uploadQrCode: isThaiLang.value ? "อัปโหลด QR Code รับเงินของธนาคารเพื่อให้แขกสแกน (ในแอปเขียนคำอวยพร)" : "Upload QR Code for guests to scan (Wish app)",
      remarkUploadQrCode: isThaiLang.value ? "หมายเหตุ: ไม่ใช่รูป QR Code พร้อมเพย์" : "Remark: Not a PromptPay QR Code image.",
      promptpayLabel: isThaiLang.value ? "พร้อมเพย์" : "Promptpay",
      bankLabel: isThaiLang.value ? "ธนาคาร" : "Bank Account",
      channelLabel: isThaiLang.value ? "ช่องทาง" : "Channel",
      accountNoLabel: isThaiLang.value ? "หมายเลขบัญชี" : "Bank Account No.",

      confirm: {
        title: isThaiLang.value ? "ต้องการเปลี่ยนแปลงข้อมูล ?" : "Edit Promptpay / Bank Account ?",
        text1: isThaiLang.value ? "รหัสจะถูกส่งไปยังเบอร์มือถือของเจ้าของงาน" : "The pin will be sent to the event owner's phone number.",
        text2: isThaiLang.value ? "กรุณากรอกรหัสที่นี่เพื่อยืนยันตัวตน" : "Please enter pin to verify your identity.",
        error: isThaiLang.value ? "รหัสไม่ถูกต้อง กรุณาลองใหม่" : "Pin is not correct, please try again",
        resendText1: isThaiLang.value ? "กดส่งใหม่ได้อีกครั้งใน" : "you can resend in",
        resendText2: isThaiLang.value ? "ไม่ได้รับข้อความใช่ไหม ?" : "Didn't receive code ?",
        resendText3: isThaiLang.value ? "ส่งอีกครั้ง" : "Resend",
        resendUnit: isThaiLang.value ? "วินาที" : "s",
      },
      error: {
        title: isThaiLang.value ? "ขออภัย" : "Sorry",
        description: isThaiLang.value ? "ไม่สามารถเปลี่ยนแปลงข้อมูลพร้อมเพย์ได้" : "Unable to change the PromptPay information.",
        remark: isThaiLang.value ? "สิทธิ์ในการเปลี่ยนแปลงข้อมูลพร้อมเพย์มีเพียงเจ้าของงานเท่านั้น" : "Only the owner has the authority to change the PromptPay information."
      }
    }
  })

  const contentPromptpayShare = computed(() => {
    return {
      title: isThaiLang.value ? "แชร์ข้อมูลงาน" : "Choose data sharing",
      list: [
        {
          id: "qrcode",
          title: isThaiLang.value ? "รูป QR-CODE  (สำหรับให้แขกส่งสลิป)" : "QR-CODE (For guests)",
          description: isThaiLang.value ? "กดที่รูปค้างไว้ แล้วเลือก save เพื่อดาวน์โหลดลงเครื่อง" : "Press on QR code image and select `save` for download",
          label: isThaiLang.value ?  "สร้าง QR CODE" : "Generate QR CODE",
          type:"image",
        },
        {
          id: "downloadPDF",
          title: isThaiLang.value ? "ดาวน์โหลด PDF" : "Download PDF",
          description: isThaiLang.value ? "Photowish จะส่งลิ้งค์สำหรับดาวน์โหลดให้ท่านในไลน์ (@Photowish) ท่านสามารถตรวจสอบในช่องทางไลน์หลังจากกดดาวน์โหลดไฟล์" : "Line Photowish will send link for download PDF file, please check inbox line again,",
          label: isThaiLang.value ?  "ดาวน์โหลดไฟล์" : "Download file",
          type:"download",
          callback: async () => {
            try {
              const promptpayData = eventStore?.event?.promptpay;  
              if(!promptpayData?.account) {
                return Swal.fire({
                  position: "center",
                  icon: "error",
                  title: " !",
                  text: "กรูณาตั้งค่าข้อมูลพร้อมเพย์ให้เสร็จก่อนดาวน์โหลด",
                  showConfirmButton: true,
                  // timer: 1500
                });
              }
              appStateStore.loading = true
            
              const url = await  download().downloadPromptpayFree(eventStore.eventId, promptpayData.account)

              if(url) {
                await notificationStore.sendToLine({
                  createdAt: new Date().toISOString(),
                  text: `[ข้อความอัตโนมัติ]: ลิ้งค์สำหรับดาวน์โหลด  Promptpay PDF \n\n ${url} \n\n สามารกด หรือ Copy ที่ลิ้งค์ แล้วนำไปเปิดบน Browser / Chrome เพื่อทำการเปิดไฟล์หรือดาวน์โหลดได้เลยค่ะ`,
                  from: "photowishApp",
                  to: authStore?.authUser?.uid,
                })
              }

              setTimeout(() => {
                appStateStore.loading = false
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Success !",
                  text: "กรุณาตรวจสอบ ลิ้งค์สำหรับดาวน์โหลดไฟล์ได้ที่ข้อความทางไลน์ @Photowish",
                  showConfirmButton: true,
                  // timer: 1500
                });
              }, 1000);
            } catch(error) {
              console.log(error)
              alert('Something went wrong, Please try again')
            }
          }
        },
      ]
    }
  })

  const contentPromptpayHowToUse = computed(() => {
    return {
      title: isThaiLang.value ? 'ขั้นตอนการใช้งาน' : 'How To Use',
      list: [
        {
          title: 'step 1',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 2',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 3',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 4',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
      ]
    }
  })

  const contentPromptpayFaqs = computed(() => {
    return {
      title: isThaiLang.value ? 'คำถามที่พบบ่อย' : 'FAQs',
      list: [
        {
          question: "PhotoWish คืออะไร",
          answer: "PhotoWish คือการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และบ่าวสาวจะได้รับรูปเล่มคำอวยพรด้วยรูป (WishBook) จัดส่งให้ในภายหลัง",
          open: false,
        },
        {
          question: "แขกจะเขียนอวยพรได้อย่างไร",
          answer: "สำหรับแพ็คเกจโปร ทางเรา QR-CODE และ Stand QR-Code(A4) จัดส่งไปให้ล่วงหน้าในวันงานเจ้าบ่าวเจ้าสาวเพียงตั้ง QR-CODE ไว้ที่จุดลงทะเบียน(จุดรับของชำร่วย/ใส่ซอง) แขกแค่สแกนและอวยพร แทนการเขียนด้วยสมุด (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น)",
          open: false,
        },
        {
          question: "แขกเลือกรูปที่จะอวยพรได้จากไหน",
          answer: "หลังจาก Scan แล้วจะเข้าสู่ระบบ, เลือกรูปจาก Gallery ในมือถือของตัวเองได้เลย และเขียนคำอวยพรได้ทันที (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น) ",
          open: false,
        },
        {
          question: "การต่อภาพ Slides และ รูปคำอวยพรแบบRealtime จะทำอย่างไร",
          answer: "เจ้าบ่าวเจ้าสาวสามารถแชร์ลิ้งค์ ให้กับทางโรมแรมเพื่อนำไปเปิดบนจอในห้องงานเลี้ยงได้เลย (การเปิด Slide Online เหมือนเปิดลิ้งค์ Youtube ทั่วไป) ",
          open: false,
        },
        {
          question: "ฉันสามารถแก้ไขและจัดเรียงรูปถ่ายในโฟโต้บุ๊คได้ไหม",
          answer: "หลังจากจบงาน ทางเราผลิต WishBook และจัดส่งให้ตามวันที่เจ้าบ่าวเจ้าสาวกำหนดไว้ในระบบ, กรณีส่ง messenger เฉพาะกรุงเทพมหานครและปริมณฑลจะได้รับหนังสือภายในวันนั้น (มีค่าจัดส่งเพิ่มเติม), กรณีส่ง ไปรษณีย์ จะได้รับภายใน 2-3 วัน(ขึ้นกับบริษัทขนส่ง) ",
          open: false,
        },
        {
          question: "ในวันแต่งจะมีเจ้าหน้าที่มาที่หน้างานหรือไม่",
          answer: "PhotoWish เป็นระบบออนไลน์ จะไม่มีเจ้าหน้าที่ไปอยู่ที่งานแต่ง  (แต่จะมีเจ้าหน้าที่ IT support ช่วยเหลือทางออนไลน์ในกรณีติดปัญหาการใช้งาน โทร: 063-048-4510)",
          open: false,

        },
        {
          question: "แขกในงานจะได้รับรูปปริ้นหรือไม่",
          answer: "ทางแขกในงานจะสามารถดาวน์โหลดรูปคำอวยพร (wish image) ได้ทันทีในระบบอวยพร, แต่จะไม่มีรูปปริ้นออกมาเป็นกระดาษจริงภายในงาน",
          open: false,

        },
        {
          question: "PhotoWish แตกต่างจาก PhotoBooth อย่างไร",
          answer: "PhotoWish จะเป็นการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และจัดทำเป็นรูปเล่มคำอวยพรจัดส่งให้ในวันถัดไป, PhotoBooth จะเป็นการถ่ายรูป 3 ท่าทาง ให้กับแขกที่มาในงานที่บูธถ่ายรูป และจะได้รับรูปถ่ายเป็นที่ระลึก",
          open: false,
        },
      ]
    }
  })
  

  // ---- computed to get current content by page -----
  const currentContent = computed(() => {
    let content: any = {}
    switch (page) {

     // ------ Promptpay -------
      case 'promptpay-home':
        content = contentPromptpayHome.value
        break;

      case 'promptpay-info':
        content = contentPromptpayInfo.value
        break;

      case 'promptpay-share':
        content = contentPromptpayShare.value
        break;

      case 'promptpay-howToUse':
        content = contentPromptpayHowToUse.value
        break;

      case 'promptpay-faqs':
        content = contentPromptpayFaqs.value
        break;


      default:
        break;
    }

    return content
  })

  return {
    content: currentContent, // The localized content
  }
}
