<template>
  <div class="p-3">
    <div class="bg-white rounded-xl shadow">
      <div class=" mx-auto max-w-7xl p-6 ">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2
            class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
          >
            {{content?.title}}
          </h2>
          <dl class="mt-5 space-y-6 divide-y divide-gray-900/10">
            <div class="pt-6" v-for="q in list" :key="q?.question">
              <dt>
                <!-- Expand/collapse question button -->
                <button
                  type="button"
                  class="flex w-full items-start justify-between text-left text-gray-900"
                  aria-controls="faq-0"
                  aria-expanded="false"
                  @click="q.open = !q.open"
                >
                  <span class="text-base font-semibold leading-7"
                    >{{ q.question }} ?</span
                  >
                  <span class="ml-6 flex h-7 items-center">
                    <svg
                      :class="{ hidden: q.open }"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>

                    <svg
                      :class="{ hidden: !q.open }"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 12H6"
                      />
                    </svg>
                  </span>
                </button>
              </dt>
              <dd v-if="q.open" class="mt-4 pr-12" id="faq-0">
                <p class="text-base leading-7 text-gray-500 font-light">
                  {{ q.answer }}
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
  

<script setup>
import { useContentPromptpay } from '~/composables/content/v2/useContentPromptpay'
const { content } = useContentPromptpay('promptpay-faqs')

const list = ref(content.value?.list)
</script>

<style>
</style>